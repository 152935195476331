import { useRef } from 'react';
import { useSnackbar, SnackbarKey, SnackbarMessage } from 'notistack';

type Variant = 'default' | 'info' | 'success' | 'error' | 'warning';

type MessageFunction = (
  message: SnackbarMessage,
  autoHideDuration?: number,
) => SnackbarKey;

type UseMessageReturnType = Record<Variant, MessageFunction>;

export default function usePageContainer() {
  return [useMessage()];
}

export function useMessage(): UseMessageReturnType {
  const { enqueueSnackbar } = useSnackbar();

  return useRef(
    (['default', 'info', 'success', 'error', 'warning'] as const).reduce(
      (accum, variant) => {
        accum[variant] = (message, autoHideDuration = 5000) =>
          enqueueSnackbar(message, { variant, autoHideDuration });
        return accum;
      },
      {} as UseMessageReturnType,
    ),
  ).current;
}
