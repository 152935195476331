import React, { Fragment, useCallback, useState } from 'react';

import { useIntl } from 'react-intl';

import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';

import { isProd } from 'src/config';
import { useLoginUserMutation } from 'src/pages/Auth/api';
import { ReactComponent as UcmLogoIcon } from 'src/images/UcmLogo.svg';

import messages from './messages';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  formContainer: {
    maxWidth: 400,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5),
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  submit: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '&:first-of-type': {
      marginTop: theme.spacing(3),
    },
  },
}));

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { mutate: loginUser, isPending } = useLoginUserMutation();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleLogin = useCallback(() => {
    loginUser({ email, password });
  }, [email, password]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (name === 'email') {
        setEmail(value);
      } else if (name === 'password') {
        setPassword(value);
      }
    },
    [],
  );

  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Paper className={classes.formContainer} elevation={6}>
        <UcmLogoIcon height={80} />

        <Typography component="h1" variant="h4" textAlign={'center'} mb={1}>
          {formatMessage(messages.title)}
        </Typography>

        <Typography variant="subtitle1" textAlign={'center'} mb={1}>
          {formatMessage(messages.subtitle)}
        </Typography>

        <form noValidate>
          {isProd && (
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {}}
            >
              {formatMessage(messages.signInWithGoogle)}
            </LoadingButton>
          )}
          {!isProd && (
            <Fragment>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={formatMessage(messages.emailAddressLabel)}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={formatMessage(messages.passwordLabel)}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />
            </Fragment>
          )}
          {!isProd && (
            <LoadingButton
              fullWidth
              loading={isPending}
              id="loginButton"
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handleLogin}
            >
              {formatMessage(messages.signIn)}
            </LoadingButton>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default LoginPage;
