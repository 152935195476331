import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const accessToken = 'access_token';

export const setAccessToken = (token: string) =>
  Cookies.set(accessToken, token);

export const removeAccessToken = () => Cookies.remove(accessToken);

export const getAccessToken = () => Cookies.get(accessToken);

export const isAuthenticated = () => !!getAccessToken();

export const requireAuthentication = <P extends object>(
  PageComponent: React.ComponentType<P>,
) => {
  const AuthenticatedComponent: React.FC<P> = (props) => {
    const navigate = useNavigate();

    if (isAuthenticated()) return <PageComponent {...props} />;

    navigate('/login');
    return null;
  };

  return AuthenticatedComponent;
};
