import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LoginPage from 'src/pages/Auth/LoginPage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
