import React from 'react';

import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

// import * as Sentry from '@sentry/react';
import { SnackbarKey, SnackbarOrigin, SnackbarProvider } from 'notistack';

import Router from 'src/router';
import defaultTheme from 'src/utils/customTheme';
import { isDev, isProd, locale, queryClient } from 'src/config';
import { QueryClientProvider } from '@tanstack/react-query';

import { translationMessages } from './i18n';
import { LanguageProvider } from './providers/languageProvider';

const notistackRef = React.createRef<SnackbarProvider>();

const notistackAnchor: SnackbarOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};

const renderNotistackAction = (key: SnackbarKey) => {
  return (
    <IconButton
      color="inherit"
      size="small"
      onClick={() => notistackRef.current?.closeSnackbar(key)}
    >
      <HighlightOffOutlinedIcon />
    </IconButton>
  );
};

export default function App() {
  // We need to change these sentry keys according to new project setup
  // TODO
  // if (isProd) {
  //   Sentry.init({
  //     dsn: 'https://567bed0b8d8a4f4fa539f0a38b31b24b@sentry.io/1847802',
  //     environment: process.env.SERVER_ENV,
  //     tracesSampleRate: 0.25,
  //     integrations: [Sentry.browserTracingIntegration()],
  //     tracePropagationTargets: [config.endpointHost],
  //   });
  // }

  // if (!isDev && !isProd) {
  //   Sentry.init({
  //     dsn: 'https://567bed0b8d8a4f4fa539f0a38b31b24b@sentry.io/1847802',
  //     environment: process.env.SERVER_ENV,
  //   });
  // }

  return (
    <LanguageProvider locale={locale} messages={translationMessages}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={defaultTheme}>
          <SnackbarProvider
            ref={notistackRef}
            action={renderNotistackAction}
            anchorOrigin={notistackAnchor}
            autoHideDuration={1000}
            maxSnack={10}
          >
            <Router />
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </LanguageProvider>
  );
}
