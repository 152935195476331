import { QueryClient } from '@tanstack/react-query';

// development
const development = {
  endpointHost: 'https://falcon-api.development.aws.ucastme.de',
  googleClientId:
    '9322604671-f092od1hhno2hnjk5v3ch35fb24sf61c.apps.googleusercontent.com',
};

// staging
const staging = {
  endpointHost: 'https://falcon-api.staging.aws.ucastme.de',
  googleClientId:
    '9322604671-f092od1hhno2hnjk5v3ch35fb24sf61c.apps.googleusercontent.com',
};

// production
const production = {
  endpointHost: 'https://falcon-api.production.aws.ucastme.de',
  googleClientId:
    '9322604671-f092od1hhno2hnjk5v3ch35fb24sf61c.apps.googleusercontent.com',
};

const getConfig = () => {
  const env = process.env.SERVER_ENV || 'staging';

  switch (env.toLowerCase()) {
    case 'development':
      return development;
    case 'production':
      return production;
    case 'staging':
    default:
      return staging;
  }
};

export const config = getConfig();
export const queryClient = new QueryClient();

export const isDev =
  process.env.SERVER_ENV && process.env.SERVER_ENV.includes('development');
export const isProd = process.env.SERVER_ENV === 'production';

export const locale = 'de';
export const currency = 'EUR';
