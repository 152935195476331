import { API } from 'src/reactQuery/Api';
import { postRequest } from 'src/utils/request';
import { useMutation } from '@tanstack/react-query';
import { setAccessToken } from 'src/utils/authentication';
import { RequestMinimalData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';

type Payload = {
  email: string;
  password: string;
};

const loginUser = async ({ email, password }: Payload) => {
  const params = { admin: { email, password } };
  const { data } = await postRequest<RequestMinimalData<UCM.LoginResponseType>>(
    API.POST.loginUser,
    params,
  );

  return data;
};

export const useLoginUserMutation = () => {
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: loginUser,
    onSuccess: ({ data: { attributes } }) => {
      setAccessToken(attributes.token);
      handleSuccess('User login successful');
    },
    onError: handleError,
  });
};
