// Importing JSON files with types
import enTranslationMessages from './translations/en.json';
import deTranslationMessages from './translations/de.json';

// Set up the default locale
const DEFAULT_LOCALE = 'en';

// Define the supported locales
const appLocales = ['en', 'de'];

// Type definition for the translation messages
export interface TranslationMessageType {
  [key: string]: string;
}

// Function to format translation messages
const formatTranslationMessages = (
  locale: string,
  messages: TranslationMessageType,
): TranslationMessageType => {
  // Format default messages if the locale is not the default one
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(
          DEFAULT_LOCALE,
          enTranslationMessages as TranslationMessageType,
        )
      : {};

  // Flatten and format the messages
  const flattenFormattedMessages = (
    formattedMessages: TranslationMessageType,
    key: string,
  ) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return { ...formattedMessages, [key]: formattedMessage };
  };

  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages: Record<string, TranslationMessageType> = {
  en: formatTranslationMessages(
    'en',
    enTranslationMessages as TranslationMessageType,
  ),
  de: formatTranslationMessages(
    'de',
    deTranslationMessages as TranslationMessageType,
  ),
};

export {
  appLocales,
  DEFAULT_LOCALE,
  translationMessages,
  formatTranslationMessages,
};
