import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import { TranslationMessageType } from 'src/i18n';

interface LanguageProviderProps {
  locale: string;
  children: ReactNode;
  messages: Record<string, TranslationMessageType>;
}

export function LanguageProvider({
  locale,
  messages,
  children,
}: LanguageProviderProps) {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
}
