const GET = {};

const POST = {
  loginUser: '/login/admin',
};

const PUT = {};

const DELETE = {};

export const API = {
  GET,
  POST,
  PUT,
  DELETE,
};
