// import config from 'src/config';
// import { getAccessToken } from './authentication';

// const noBodyCodes = [204, 205, 401, 404, 500];

// const { endpointHost } = config;

// function hasNoBody(response) {
//   return noBodyCodes.includes(response.status);
// }

// /**
//  * Parses the JSON returned by a network request
//  *
//  * @param  {object} response A response from a network request
//  *
//  * @return {object}          The parsed JSON from the request
//  */
// function parseJSON(response) {
//   if (!response || hasNoBody(response)) return null;
//   return response.json();
// }

// /**
//  * Parses the Blob returned by a network request
//  *
//  * @param  {object} response A response from a network request
//  *
//  * @return {object}          The parsed binary content from the request
//  */
// function parseBlob(response) {
//   if (!response || hasNoBody(response)) return null;
//   return response.blob();
// }

// /**
//  * Parses the XML returned by a network request
//  *
//  * @param  {object} response A response from a network request
//  *
//  * @return {object}          The parsed XML from the request
//  */
// // function parseXml(response) {
// //   if (hasNoBody(response)) return null;
// //   return response
// //     .text()
// //     .then(str => new DOMParser().parseFromString(str, 'application/xml'));
// // }

// /**
//  * Parses the text returned by a network request
//  *
//  * @param  {object} response A response from a network request
//  *
//  * @return {object}          The parsed text from the request
//  */
// function parseText(response) {
//   if (hasNoBody(response)) return null;
//   return response.text();
// }

// /**
//  * Checks if a network request came back fine, and throws an error if not
//  *
//  * @param  {object} response   A response from a network request
//  *
//  * @return {object|undefined} Returns either the response, or throws an error
//  */
// function checkStatus(response) {
//   if (response.status >= 200 && response.status < 300) {
//     return { response };
//   }
//   const error = new Error(response.statusText || response.status);
//   error.status = response.status;
//   return { response, error };
// }

// function parseResponse(type) {
//   return ({ response, error }) => {
//     if (error || !type) return Promise.all([parseJSON(response), error]);
//     if (type === 'text') return Promise.all([parseText(response), error]);
//     if (type === 'blob') return Promise.all([parseBlob(response), error]);
//     // if (type === 'xml') return Promise.all([parseXml(response), error]);
//     return [response, error];
//   };
// }

// function resolveResponse([response, error]) {
//   const exception = error;
//   if (exception) {
//     exception.body = response;
//     throw exception;
//   }
//   return response;
// }

// /**
//  * Convert a JSON object, returning a query string
//  *
//  * @param  {object} params   JSON object
//  * @param  {string} [prefix] Param name for the object (params)
//  *
//  * @return {string}          The query string
//  */
// export function serialize(params, prefix = '') {
//   if (!params) return '';
//   const query = Object.keys(params).reduce((accum, item) => {
//     let key = item;
//     const value = params[key];
//     if (params.constructor === Array) {
//       key = `${prefix}[]`;
//     } else if (params.constructor === Object) {
//       key = prefix ? `${prefix}[${key}]` : key;
//     }
//     if (value === undefined) {
//       return accum;
//     }
//     if (typeof value === 'object') {
//       const moreParams = serialize(value, key);
//       if (moreParams) accum.push(moreParams);
//     } else {
//       accum.push(`${key}=${encodeURIComponent(value)}`);
//     }
//     return accum;
//   }, []);
//   return query.join('&');
// }

// /**
//  * Requests a URL, returning a promise
//  *
//  * @param  {string} url            The URL we want to request
//  * @param  {object} [params]       The params we want to pass to "fetch"
//  * @param  {string} [responseType] The response type for parsing
//  *
//  * @return {object}                The response data
//  */
// export default function request(url, params, responseType) {
//   const options = getOptions('GET', null, params);
//   return fetch(`${endpointHost}/${url}`, options)
//     .then(checkStatus)
//     .then(parseResponse(responseType))
//     .then(resolveResponse);
// }

// export function get(url, responseType, params = {}) {
//   return request(url, getOptions('GET', null, params), responseType);
// }

// export function post(url, object, params = {}, responseType) {
//   return request(url, getOptions('POST', object, params), responseType);
// }

// export function put(url, object, params = {}) {
//   return request(url, getOptions('PUT', object, params));
// }

// export function patch(url, object) {
//   return request(url, getOptions('PATCH', object));
// }

// export function del(url, object, params = {}) {
//   return request(url, getOptions('DELETE', object, params));
// }

// function buildBody(method, object) {
//   if (method === 'GET' || !object) return null;
//   const isFormData = object instanceof FormData;
//   return isFormData ? object : JSON.stringify(object);
// }

// export function getOptions(method = 'GET', object, params) {
//   const body = buildBody(method, object);
//   const options = { body, ...params };
//   return {
//     method,
//     ...options,
//     credentials: 'same-origin',
//     headers: getHeaders(options),
//   };
// }

// function getHeaders(params) {
//   const token = getAccessToken();
//   const headers = params?.headers;
//   const isFormData = params?.body instanceof FormData;
//   let contentType = { 'Content-Type': 'application/json' };
//   if (isFormData) contentType = null;
//   return {
//     ...(token ? { Authorization: `bearer ${token}` } : null),
//     Accept: 'application/vnd.falcon.v1+json',
//     'X-Requested-With': 'XMLHttpRequest',
//     ...contentType,
//     ...headers,
//   };
// }

import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  HttpStatusCode,
} from 'axios';
import { config } from 'src/config';
const noBodyCodes = [204, 205, 401, 404, 500];

const { endpointHost } = config;

axios.defaults.baseURL = endpointHost;
axios.defaults.headers.common.Accept = 'application/vnd.falcon.v3+json';
axios.defaults.headers.common['Accept-Language'] = 'en';

export const hasNoBody = (response: { status: HttpStatusCode }) => {
  return noBodyCodes.includes(response.status);
};

export const setupAxios = (token: any, locale: any) => {
  if (token != null)
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  else if (token === null) delete axios.defaults.headers.common.Authorization;
  axios.defaults.headers.common['Content-Language'] = locale || 'en';
};

const http = () => axios;

export const getRequest = async <T = any>(
  url: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.get<T>(url, options);
    return response;
  } catch (error) {
    throw error;
  } finally {
  }
};

export const postRequest = async <T = any>(
  url: string,
  data?: any,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.post<T>(url, data, options);
    return response;
  } catch (error) {
    throw error;
  } finally {
  }
};

export const deleteRequest = async <T = any>(
  url: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.delete<T>(url, options);
    return response;
  } catch (error) {
    throw error;
  } finally {
  }
};

export const putRequest = async <T = any>(
  url: string,
  data?: any,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.put(url, data, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const patchRequest = async <T = any>(
  url: string,
  data?: any,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.patch(url, data, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export default http;
